import { Controller } from "@hotwired/stimulus"
import $ from "jquery";
import "select2";

export default class extends Controller {
  static targets = ["select"]
  
  connect(){
    this.select2Instances = new Array
    this.setupSelect2()
  }
  disconnect(){
    Initializers.deleteSelect2()
  }

  setupSelect2(){
    if ($(this.selectTarget).hasClass('.multiple')) {
      this.select2Instance = $(this.selectTarget).select2({
        placeholder: $(this.selectTarget).data('placeholder'),
        width: '100%',
        tags: true,
        allowClear: true
      })
    } else {
      this.select2Instance = $(this.selectTarget).select2({
        placeholder: $(this.selectTarget).data('placeholder'),
        width: '100%'
      })
    }
  }

  deleteSelect2(){
    $(this.selectTarget).select2('destroy')
  }
}