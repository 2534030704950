Form = {
  reset: (element) => {
    $(element).each((index, selector)=>{
      selector.reset()
    });

    $('.select2').val(null).trigger('change');

    if ($('.is-invalid').length > 0) {
      $('.is-invalid').each( (index, selector)=>{
        $(selector).removeClass('has-error')
      })
    }

    if ($('.invalid-feedback').length > 0) {
      $('.invalid-feedback').each((index, selector)=>{
        $(selector).remove()
      });
    }

    if ($(element).find('.alert-danger').length >0){
      $(element).find('.alert-danger').remove();
    }
  },

  displayErrors: (selector, errors) =>{
    $(selector).prepend(`<div class="alert alert-danger" role='alert'>A few errors are preventing you from continuing.</div>`);
    JSON.parse(errors, (key, value) =>{
      $(`${selector}_${key}`).addClass('is-invalid')
      $(`${selector}_${key}`).parent('.form-group').append(`<div class='invalid-feedback'>${value}</div>`)
    });
  },

  init: ()=> {
    $('.select2').select2({
      placeholder: $('.select2').data('placeholder'),
      width: '100%'
    });
  },

  displayFormSection: (formNav)=> {
    const existingSection = $('.form-section.active')
    const formSection = $(`${formNav.data('id')}`)
    existingSection.removeClass('active')
    formSection.addClass('active')
  },

  updateFormNavigation: (formNav)=> {
    const existingFormLink = $('.form-nav-link.active')
    existingFormLink.removeClass('active')
    formNav.addClass('active')
  },

  selectFormSection: ()=>{
    $(document).on('click', '.form-nav-link', function(e){
      e.preventDefault()
      let formNav = $(e.currentTarget)
      Form.displayFormSection(formNav)
      Form.updateFormNavigation(formNav)
    });
  },
  formatGoodsAndServicesData: (selected)=>{
    const data = []
    $.each(selected, function (index,obj) {
      console.log(obj)
      let selection = {
        "id": obj[0],
        "text": obj[1],
        "selected": true
      } // replace pk with your identifier
      data.push(selection)
    })
  return data
},
  classificationSelect:()=>{
    if ($('.classification-select').length > 0){
      $('.classification-select').select2({
        placeholder: $('.classification-select').data('placeholder'),
        width: '100%',
        allowClear: true,
        data: Form.formatGoodsAndServicesData($('.classification-select').data('selected')),
        ajax: {
          url: '/trademarks/goods_and_services',
          dataType: 'json',
          delay: 250,
          data: function (params){
            return{
              q: params.term,
              page: params.page || 1
            };
          },
          processResults: function(data, params) {

            params.page = params.page || 1;

            return{
              results: data.results,
              pagination: {
                more: (params.page * 30) < data.total_count
              }
            }
          }
        },
        cache: true
      });
    }
  },
  bankSelect:()=>{
    console.log("bank selected!")
    $('.bank-select').select2({
      width: '100%',
      allowClear: true,
      ajax: {
        url: '/bank_accounts/banks',
        dataType: 'json',
        delay: 250,
        data: function(params){
          return {
            q: $('#bank_account_country').select2('data')[0].id
          }
        }
      }
    })
  },
  branchSelect:()=>{
    console.log("branch selected!")
    $('.branch-select').select2({
      width: '100%',
      allowClear: true,
      ajax: {
        url: '/bank_accounts/branches',
        dataType: 'json',
        delay: 250,
        data: function(params){
          return {
            q: $('#bank_account_bank').select2('data')[0].text
          }
        }
      }
    })
  }
}
Form.selectFormSection()
document.addEventListener('turbo:load', ()=>{
  Form.classificationSelect()
});