
import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import Informer from '@uppy/informer'
import ProgressBar from '@uppy/progress-bar'
import StatusBar from '@uppy/status-bar'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import AwsS3  from '@uppy/aws-s3'

import Cropper from 'cropperjs';

function fileUpload(fileInput) {
  const hiddenInput = document.querySelector('.document-upload-data'),
        imagePreview = document.querySelector('.document-upload-preview'),
        formGroup = fileInput.parentNode

  // remove our file input in favour of Uppy's
  formGroup.removeChild(fileInput)

  const uppy = new Uppy({
      autoProceed: true,
    })
    .use(FileInput, {
      target: formGroup,
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(StatusBar, {
      target: imagePreview.parentNode,
      showProgressDetails: true
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })
    .use(AwsS3, {
      companionUrl: '/', // will call the presign endpoint on `/s3/params`
    })

  uppy.on('file-added', (file)=>{
    console.log(file)
    let template = `<div class="document-preview"><i class="far fa-file-excel"></i><span>${file.name}</span> - <strong>${Utilities.bytesToSize(file.size)}</strong></div>`
    document.querySelector('.document-upload-preview').innerHTML = template
  })

  uppy.on('upload', (file)=>{
    let uploadText = $('.document-upload-button').text()
    let uploadButton = $('.document-upload-button')
    console.log(file)
  })
  // uppy.on('thumbnail:generated', (file, preview) => {
  //   // show preview of the image using URL from thumbnail generator
  //   imagePreview.src = preview
  //
  //
  // })

  uppy.on('upload-success', (file, response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }
    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData)

  })
}

function multiplePhotoUpload(fileInput) {
  const formGroup = fileInput.parentNode

  formGroup.removeChild(fileInput)

  const uppy = new Uppy({
    autoProceed: true,
  })
  .use(FileInput, {
    target: formGroup,
  })
  .use(Informer, {
    target: formGroup,
  })
  .use(ProgressBar, {
    target: "#progress-bar",
  })
  .use(ThumbnailGenerator, {
    thumbnailWidth: 200,
    thumbnailHeight: 200
  })
  .use(AwsS3, {
    companionUrl: '/', // will call the presign endpoint on `/s3/params`
  })

  uppy.on('file-added', (file)=>{
    console.log(file)
    $('#add-photo-button').trigger('click')
    let locationID = $('#photos-list > .photo').last().attr('id').replace(/\D/g,'')
    file.meta['locationId'] = locationID
  })
  
  uppy.on('upload', (file)=>{

  })

  uppy.on('thumbnail:generated', (file, preview)=>{
    let imagePreview = $(`#photo-${file.meta['locationId']}`).find('img')[0]
    imagePreview.src = preview
  })

  uppy.on('upload-success', (file, response)=>{
    let hiddenInput;
    
    if ($('#new_projects_completion_request').length > 0) {
      hiddenInput = document.querySelector(`#projects_completion_request_photos_attributes_${file.meta['locationId']}_image`)
    } else if ($('#new_album').length > 0){
      hiddenInput = document.querySelector(`#album_photos_attributes_${file.meta['locationId']}_image`)
    } else {
      hiddenInput = document.querySelector(`#project_photos_attributes_${file.meta['locationId']}_image`)
    }
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }
    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData)
  })
}

function imageUpload(fileInput) {
  const hiddenInput = document.querySelector(`.image-upload-data`),
        imagePreview = document.querySelector(`.image-upload-preview`),
        formGroup = fileInput.parentNode

  // remove our file input in favour of Uppy's
  formGroup.removeChild(fileInput)

  const uppy = new Uppy({
      autoProceed: true,
    })
    .use(FileInput, {
      target: formGroup,
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(ProgressBar, {
      target: imagePreview.parentNode,
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })
    .use(AwsS3, {
      companionUrl: '/', // will call the presign endpoint on `/s3/params`
    })

  uppy.on('upload', (file)=>{
    let uploadText = $('.photo-upload-button').text()
    let uploadButton = $('.photo-upload-button')
  })

  uppy.on('thumbnail:generated', (file, preview) => {
    // show preview of the image using URL from thumbnail generator
    imagePreview.src = preview


  })

  uppy.on('upload-success', (file, response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }
    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData)

    let cropData = {}
    let fullImage = imagePreview
    fullImage.src = `/photos/load?key=${file.meta['key']}`
    const cropper = new Cropper(fullImage, {
      viewMode: 1,
      aspectRatio: 1 / 1,
      crop(event) {
        cropData = {
          x: Math.round(event.detail.x),
          y: Math.round(event.detail.y),
          width: Math.round(event.detail.width),
          height: Math.round(event.detail.height),
          rotate: Math.round(event.detail.rotate),
          scale_x: Math.round(event.detail.scaleX),
          scale_y: Math.round(event.detail.scaleY)
        }
        let fileData = JSON.parse(hiddenInput.value)
        fileData['metadata']['crop'] = cropData
        hiddenInput.value = JSON.stringify(fileData)
      }
    })
  })
}


document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.document-upload-file').forEach(fileInput => {
    fileUpload(fileInput)
  })
})

document.addEventListener('turbo:load', ()=> {
  document.querySelectorAll('.multiple-upload-file').forEach(fileInput => {
    multiplePhotoUpload(fileInput)
  })
  document.querySelectorAll('.image-upload-file').forEach(fileInput => {
    imageUpload(fileInput)
  })
})

$('#photos-list').on('cocoon:after-insert', function(e, photo){
  $(photo).attr('id',`photo-${photo.find('input').first().attr('id').replace(/\D/g,'')}`)
})
