import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["picker"]

  connect() {
    console.log("datetime connected")
    this.setupFlatPickr()
  }
  
  disconnect(){
    this.deleteFlatpickr()
  }

  setupFlatPickr(){
    const pickerElement = this.pickerTarget
    console.log("checking class List")
    console.log(pickerElement.classList)
    console.log("checking boolean")
    console.log(pickerElement.classList.contains('datetimepicker'))
    if (pickerElement.classList.contains('datetimepicker')){
      this.pickerInstance = flatpickr(pickerElement, {
        wrap: true,
        enableTime: true, 
        dateFormat: "Y-m-d H:i",
        altInput: true,
        altFormat: "F j, Y, H:i"
      })
    } else if (pickerElement.classList.contains('datepicker')){
      this.pickerInstance = flatpickr(pickerElement,{
        wrap: true,
        enableTime: false,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y"
      })
    } else if (pickerElement.classList.contains('timepicker')){
      this.pickerInstance = flatpickr(pickerElement, {
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i"
      })
    }
  }

  deleteFlatpickr(){
    this.pickerInstance.destroy()
  }
}