import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["country", "bank", "branch", "routing"]

  connect(){
    $(this.countryTarget).on('select2:select', this.bankSelect.bind(this))
    $(this.bankTarget).on('select2:select', this.branchSelect.bind(this))
  }

  disconnect(){

  }

  bankSelect(){
    console.log("bank selected!")
    console.log($(this.countryTarget).select2('data')[0].id)
    let query = $(this.countryTarget).select2('data')[0].id
    $(this.bankTarget).select2({
      width: '100%',
      allowClear: true,
      ajax: {
        url: '/bank_accounts/banks',
        dataType: 'json',
        delay: 250,
        data: function(params){
          return {
            q: query
          }
        }
      }
    })
  }

  branchSelect(){
    console.log("branch selected!")
    let query = $(this.bankTarget).select2('data')[0].id
    $(this.branchTarget).select2({
      width: '100%',
      allowClear: true,
      ajax: {
        url: '/bank_accounts/branches',
        dataType: 'json',
        delay: 250,
        data: function(params){
          return {
            q: query
          }
        }
      }
    })
  }
}