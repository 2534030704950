Initializers = {
  flatpickrInstances: new Array,
  select2Instances: new Array,

  setupFlatPickr: ()=>{
    let $datetimeSelectors = Array.from(document.querySelectorAll('.datetimepicker'))
    let $datepickerSelectors = Array.from(document.querySelectorAll('.datepicker'))
    let $timepickersSelectors = Array.from(document.querySelectorAll('.timepicker'))
    if ($datetimeSelectors.length > 0){
      $datetimeSelectors.forEach((element)=>{
        let fp = flatpickr(element, {
          wrap: true,
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          altInput: true,
          altFormat: "F j, Y, H:i"
        });
        Initializers.flatpickrInstances.push(fp)
      });
    }
    if ($datepickerSelectors.length > 0){
      $datepickerSelectors.forEach( (element) =>{
        let flatpickrInstance = flatpickr(element, {
          wrap: true,
          enableTime: false,
          dateFormat: "Y-m-d",
          altInput: true,
          altFormat: "F j, Y"
        })
        Initializers.flatpickrInstances.push(flatpickrInstance)
      });
    }
    if ($timepickersSelectors.length > 0){
      $timepickersSelectors.forEach( (element) =>{
        let flatpickrInstance = flatpickr(element, {
          wrap: true,
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i"
        })
        Initializers.flatpickrInstances.push(flatpickrInstance)
      });
    }
  },
  deleteFlatpickr: ()=>{
    if (Initializers.flatpickrInstances.length > 0) {
      Initializers.flatpickrInstances.forEach((element)=>{
        element.destroy()
      })
    }
  },
  setupSelect2: ()=>{
    if ($('.select2').length > 0){
      $('.select2').select2({
        placeholder: $('.select2').data('placeholder'),
        width: '100%'
      });
    }
    if ($('.select2.multiple').length > 0){
      $('.select2.multiple').select2({
        placeholder: $('.select2').data('placeholder'),
        width: '100%',
        tags: true,
        allowClear: true
      })
    }
  },
  deleteSelect2:()=>{
    if (Initializers.select2Instances.length > 0){
      Initializers.select2Instances.forEach((element)=>{
        element.select2('destroy');
      })
    }
  }
}