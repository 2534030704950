Utilities = {

  bytesToSize: function(bytes,decimals=2){
    if (bytes == 0){
      return '0 Byte'
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const k = 1024
    const dm = decimals <= 0 ? 0 : decimals
    const i = Math.floor(Math.log(bytes)/Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
}
