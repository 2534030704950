import Chart from 'chart.js/auto';

document.addEventListener('turbo:load', ()=>{
  var projectsCtx = document.getElementById('projectsChart');
  if (projectsCtx != null) {
    console.log(projectsCtx.dataset.labels)
    var projectsChart = new Chart(projectsCtx, {
      type: 'bar',
      data: {
        labels: JSON.parse(projectsCtx.dataset.labels),
        datasets: [
          {
            label: 'Projects',
            data: JSON.parse(projectsCtx.dataset.projects),
            backgroundColor: 'rgba(254,181,4,1)'
          },
          {
          label: 'Completed',
          data: JSON.parse(projectsCtx.dataset.completed),
          backgroundColor: 'rgba(45,44,186,1)'
          }
        ]
      }
    })
  }
	
  var usersCtx = document.getElementById('usersChart');
  if (usersCtx != null) {
    console.log(usersCtx.dataset.labels)
    var usersChart = new Chart(usersCtx, {
      type: 'line',
      data: {
        labels: JSON.parse(usersCtx.dataset.labels),
        datasets: [
          {
            label: 'Users',
            data: JSON.parse(usersCtx.dataset.users),
            borderColor: 'rgba(254,181,4,1)',
						tension: 0.1
          }
        ]
      },
			options: {
				scales: {
					y: {
						min: 0
					}
				}
			}
    })
  }

  var contractorsCountryCtx = document.getElementById('contractorsCountryChart')
  if (contractorsCountryCtx != null){
    console.log(contractorsCountryCtx.dataset.labels)
    var contractorsCountryChart = new Chart(contractorsCountryCtx, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(contractorsCountryCtx.dataset.labels),
        datasets: [
          {
            label: 'Contractors',
            data: JSON.parse(contractorsCountryCtx.dataset.contractors),
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)',
              'rgb(216, 216, 216)',
              'rgb(33, 143, 9)',
              'rgb(143, 9, 124)'
            ],
            hoverOffset: 4
          }
        ]
      },
			options: {
				scales: {
					y: {
						min: 0
					}
				}
			}
    })
  }

  var contractorsCtx = document.getElementById('contractorsChart')
  if (contractorsCtx != null) {
    console.log(contractorsCtx.dataset.labels)
    var contractorsChart = new Chart(contractorsCtx, {
      type: 'line',
      data: {
        labels: JSON.parse(contractorsCtx.dataset.labels),
        datasets: [
          {
            label: 'Contractors',
            data: JSON.parse(contractorsCtx.dataset.contractors),
            borderColor: 'rgba(254,181,4,1)',
						tension: 0.1
          }
        ]
      },
			options: {
				scales: {
					y: {
						min: 0
					}
				}
			}
    })
  }
})
