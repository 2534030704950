Quote = {
  displayFinalAmount: (type)=>{
    $(document).on('change keyup', `#${type}_quantity, #${type}_unit_amount`, ()=>{
      const quantity = Quote.checkValue($(`#${type}_quantity`).val())
      const unitAmount = Quote.checkValue($(`#${type}_unit_amount`).val())
      const finalAmount = (quantity * unitAmount).toFixed(2)
      $(`#${type}_amount`).val(`${finalAmount}`)
    })
  },
  checkValue: (input)=>{
    if (input.length > 0){
      return parseFloat(input)
    } else {
      return 0
    }
  },
  updateQuoteOptions:()=>{
    $(document).on('change', '#quote_currency, #quote_description, #quote_start_date, #quote_end_date', (e)=>{
      const quoteForm = $(e.currentTarget).parents('form')[0]
     quoteForm.requestSubmit()
    });
  }
}
Quote.displayFinalAmount("entry")
Quote.displayFinalAmount("labour")
Quote.displayFinalAmount("materials")
Quote.updateQuoteOptions()