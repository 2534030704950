// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "bootstrap"
import "./controllers"
require("trix")
require("@rails/actiontext")
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import select2 from 'select2';
const flatpickr = require("flatpickr");
const moment = require('moment');
require("jquery");
require("@nathanvda/cocoon");
require.context('../images', true)
import '../stylesheets/application.scss';
import './src/init.js';
import './src/form.js';
import './src/uploads.js';
import './src/utilities.js';
import './src/quotes.js';
import './src/chart.js';